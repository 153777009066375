// @flow
import * as React from 'react'

import Network from 'icons/network.svg'
import BidBoard from 'icons/checkmark-papers.svg'
import Calendar from 'icons/calendar.svg'
import AddUser from 'icons/add-user.svg'
import Analytics from 'icons/analytics.svg'
import MagnifyingGlass from 'icons/magnifying-glass.svg'
import BidForwarding from 'icons/bid-forwarding.svg'
import Shield from 'icons/shield.svg'
import InfoTable from 'icons/info-table.svg'
import { Button } from 'views/components'
import { cyan } from 'shared/colors'
import { showRequestDemoModal } from 'components/modal-manager'

export default [
	{
		icon: <Network />,
		title: 'Get discovered by GCs',
		content: 'Join our network and get discovered by thousands of top GCs.',
		pro: false,
	},
	{
		icon: <BidBoard />,
		title: 'Individual Bid Board',
		content:
			'See all your bid invites in one place and track as they move from one stage to the next.',
		pro: false,
	},
	{
		icon: <Calendar />,
		title: 'Get on one shared calendar',
		content:
			'Sync your Outlook or Google calendars, and never miss a deadline again.',
		pro: true,
	},
	{
		icon: <AddUser />,
		title: 'Shared team Bid Board',
		content:
			'Track bid invites, projects, who’s working on what and due dates across your entire office—from one place.',
		pro: true,
	},
	{
		icon: <Analytics />,
		title: 'GC analytics',
		content:
			'Get key insights, like win rates and contract values, so you can make smarter decisions.',
		pro: true,
	},
	{
		icon: <MagnifyingGlass />,
		title: 'Team performance tracking',
		content:
			'See how estimators on your team are performing and put your best on the bids you want to win most.',
		pro: true,
	},
	{
		icon: <BidForwarding />,
		title: 'Bid Forwarding',
		content:
			'Forward your ITBs and project updates from anywhere, and your bid board is automatically created.',
		pro: true,
	},
	{
		icon: <Shield />,
		title: 'Security and permissions',
		content:
			'All data is encrypted and securely stored, and you control who sees what.',
		pro: true,
	},
	{
		icon: <InfoTable />,
		title: 'Find new GCs bidding on your projects',
		content:
			'Automatically discover other GCs bidding on projects you want to win.',
		pro: true,
	},
	{
		title: 'Ready to get started?',
		content: (
			<Button
				label="Get a demo and a quote"
				padding="0 40px"
				marginTop="10px"
				onClick={() => showRequestDemoModal()}
				css={{
					backgroundColor: cyan,
				}}
			/>
		),
	},
]
