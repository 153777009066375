import * as React from 'react'
import { Div } from 'glamorous'

import Layout from 'components/layout'
import { SectionSpacer, ContentWrapper, Footer } from 'views/components'
import Header from './header'
import ROICalculator from 'views/components/roi-calc'
import { defaultCalcState, ranges, inputs } from './roi-calc-data'
import ResultsSection from './roi-calc-data/results-section'
import { laptop, tablet } from 'shared/media-queries'
import FreeVSPro from './free-vs-pro'
import RealSubs from './real-subs'
import FAQs from './faq'
import ResourceHub from './resource-hub'
import { grayBackground } from 'shared/colors'
import useScreenWidth from 'hooks/use-screen-width'
import styled from 'styled-components'

const Br = styled.br`
	display: none;
	${laptop} {
		display: block;
	}
	${tablet} {
		display: none;
	}
`

const BidBoardProPricing = () => {
	const screenWidth = useScreenWidth()
	if (!screenWidth) {
		return null
	}

	return (
		<Layout
			title="Grow Your Construction Business | BuildingConnected"
			description="How much more money can you bring in using Bid Board Pro? Find out using this growth calculator."
		>
			<Header screenWidth={screenWidth} />
			<SectionSpacer />
			<ContentWrapper>
				<ROICalculator
					defaultState={defaultCalcState}
					ranges={ranges}
					inputs={inputs}
					resultsSection={state => <ResultsSection values={state} />}
					title={
						<>
							How much can you grow with Bid Board Pro?
							<Br /> Check it out.
						</>
					}
				/>
				<SectionSpacer />
				<FreeVSPro />
				<SectionSpacer />
			</ContentWrapper>
			<Div backgroundColor={grayBackground}>
				<SectionSpacer />
				<RealSubs />
				<SectionSpacer />
			</Div>
			<ContentWrapper>
				<SectionSpacer />
				<FAQs />
				<SectionSpacer />
				<ResourceHub />
				<SectionSpacer />
			</ContentWrapper>
			<Footer
				wording="Try Bid Board Pro with your team for free."
				subtext="Invest in the preconstruction tools that will increase productivity—and your bottom line."
				demoButtonWording="Get a demo and a quote"
				demoButtonPadding="90px"
				showCreateAccount
			/>
		</Layout>
	)
}

export default BidBoardProPricing
