// @flow
import * as React from 'react'

import { Img } from 'glamorous'
import justin from './img/justin.png'
import justin2x from './img/justin@2x.png'
import justin3x from './img/justin@3x.png'
import mike from './img/mike.png'
import mike2x from './img/mike@2x.png'
import mike3x from './img/mike@3x.png'
import scott from './img/scott.png'
import scott2x from './img/scott@2x.png'
import scott3x from './img/scott@3x.png'
import { Testimonials } from 'views/components'

const RealSubs = () => (
	<Testimonials
		header="What customers are saying about us."
		data={[
			{
				image: <Img src={justin} srcSet={`${justin2x} 2x, ${justin3x} 3x`} />,
				quote: 'Bid Board Pro is a huge time saver—and time is money.',
				name: 'Justin Davis',
				title: 'VP of Preconstruction',
				company: 'Walker Engineering',
			},
			{
				image: <Img src={mike} srcSet={`${mike2x} 2x, ${mike3x} 3x`} />,
				quote:
					'Since moving to Bid Board Pro, Bowman Flooring Contractor has increased its win rate by 25% and increased revenue by $9 million.',
				name: 'Mike Adams',
				title: 'Senior Project Manager',
				company: 'Bowman Flooring Contractor',
			},
			{
				image: <Img src={scott} srcSet={`${scott2x} 2x, ${scott3x} 3x`} />,
				quote:
					'BuildingConnected played a significant role in helping Bradley Concrete increase its revenue from $3 million to $42 million.',
				name: 'Scott Bradley',
				title: 'Co-Founder',
				company: 'Bradley Concrete"',
			},
		]}
	/>
)

export default RealSubs
