// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { H2 } from 'views/components'
import { grayText, navy } from 'shared/colors'
import sectionInfo from './section-info'
import { laptop, tablet, mobile } from 'shared/media-queries'

type SectionInfo = {
	icon?: React.Node,
	title: string,
	content: React.Node,
	pro?: boolean,
}

const ProBadge = () => (
	<Div
		marginLeft="15px"
		width="47px"
		height="20px"
		backgroundColor={navy}
		color="white"
		fontSize="12px"
		letterSpacing="1px"
		display="inline-flex"
		justifyContent="center"
		alignItems="center"
		borderRadius="3px"
		paddingLeft="2px"
		fontWeight="600"
		flex="0 0 auto"
		position="relative"
		top="-5px"
	>
		PRO
	</Div>
)

const FreeVSPro = () => (
	<Div>
		<H2
			textAlign="center"
			css={{
				[tablet]: { textAlign: 'left' },
			}}
		>
			Free vs. Pro
		</H2>
		<Div
			height="30px"
			css={{
				[tablet]: { display: 'none' },
			}}
		/>
		<Div
			display="flex"
			flexWrap="wrap"
			justifyContent="space-between"
			css={{
				[tablet]: { display: 'block' },
			}}
		>
			{sectionInfo.map(({ icon, title, content, pro }: SectionInfo) => (
				<Div
					display="flex"
					flex="0 0 45%"
					key={title}
					margin="20px"
					css={{
						[laptop]: { margin: '15px', flex: '0 0 46%' },
						[tablet]: { margin: '30px 0' },
					}}
				>
					<Div flex="0 0 50px" display="flex" justifyContent="center">
						{icon ? icon : null}
					</Div>
					<Div
						flex="0 0 30px"
						css={{
							[mobile]: { flex: '0 0 10px' },
						}}
					/>
					<Div>
						<Div fontWeight="700">
							{title}
							{pro ? <ProBadge /> : null}
						</Div>
						<Div color={grayText} fontSize="18px">
							{content}
						</Div>
					</Div>
				</Div>
			))}
		</Div>
	</Div>
)

export default FreeVSPro
