// @flow
import * as React from 'react'
import { Img, Div, A } from 'glamorous'

import { ResourceHub } from 'views/components'
import videoImage from './img/video.jpg'
import videoImage2x from './img/video@2x.jpg'
import videoImage3x from './img/video@3x.jpg'
import blogImage from './img/blog.jpg'
import blogImage2x from './img/blog@2x.jpg'
import blogImage3x from './img/blog@3x.jpg'
import { navy } from 'shared/colors'

const cards = [
	{
		image: (
			<Img
				width="100%"
				src={videoImage}
				srcSet={`${videoImage2x} 2x, ${videoImage3x} 3x`}
			/>
		),
		title: 'Video',
		wording: 'How to turn time into money with our ROI calculator.',
		cta: 'Watch the video',
		videoId: 'j1smlwacok',
	},
	{
		image: (
			<Img
				width="100%"
				src={blogImage}
				srcSet={`${blogImage2x} 2x, ${blogImage3x} 3x`}
			/>
		),
		title: 'Blog',
		wording: `3 ways Bowman Flooring increased its win rate by 25% with Bid Board Pro.`,
		cta: 'Read more',
		href: `https://blog.buildingconnected.com/3-ways-bowman-flooring-contractor-increased-its-win-rate-by-25-through-buildingconnected/`,
	},
	{
		title: 'How To',
		wording: (
			<Div>
				<A
					color={navy}
					href="https://blog.buildingconnected.com/industry-leaders-share-how-they-made-the-case-for-construction-technology/"
					target="_blank"
				>
					Make the case for construction tech.
				</A>
				<br />
				<br />
				<A
					color={navy}
					href="https://drive.google.com/open?id=1rEB8_chlVy6MLYiEymnKEZDNMkMuQxvY"
					target="_blank"
				>
					Make the most out of your Bid Board trial with this checklist (PDF).
				</A>
				<br />
				<br />
				<A
					color={navy}
					href="https://drive.google.com/file/d/1P-wjtHx0gqElfhb6xOtkO8CCoOlmPb_E/view?usp=sharing"
					target="_blank"
				>
					See how much more money you could bring in with our ROI Calculator
					(Excel).
				</A>
			</Div>
		),
	},
]

const PricingResourceHub = () => (
	<ResourceHub
		title="The resource hub."
		subtitle="Everything you need to decide if Bid Board Pro is right for your team."
		cards={cards}
	/>
)

export default PricingResourceHub
