import styled from 'styled-components'
import { navy } from 'shared/colors'
import { laptop, tablet, mobile } from 'shared/media-queries'

export const BasicFlexDiv = styled.div`
	flex: 0 0 50%;
	${tablet} {
		flex: auto;
	}
`

export const ButtonContainer = styled.div`
	flex: 1 1 0%;
	${tablet} {
		flex: auto;
	}
`
export const SmallSpacer = styled.div`
	height: 5px;
`
export const SmallWidthSpacer = styled.div`
	width: 5px;
`
export const RootContainer = styled.div`
	height: 213px;
	padding: 0 75px;
	background-color: ${navy};
	${laptop} {
		height: 275px;
		padding: 0 30px;
	}
	${tablet} {
		height: inherit;
		padding: 25px;
	}
`
export const ResultsContainer = styled.div`
	height: 100%;
	display: flex;
	color: white;
	align-items: center;
	${laptop} {
		flex-direction: column;
		justify-content: center;
	}
`
export const ResultsContent = styled.div`
	flex: 1 1 0%;
	display: flex;
	width: 100%;
	${laptop} {
		flex: 0 0 auto;
	}
	${tablet} {
		order: 3;
	}
	${mobile} {
		flex-direction: column;
	}
`
export const ResultsItemContainer = styled.div`
	font-weight: 700;
	display: flex;
	align-items: center;
`
export const ResultsSpan = styled.span`
	position: relative;
	top: 2px;
`
export const TooltipHeader = styled.div`
	font-size: 20px;
	font-weight: 700;
	color: ${navy};
`
export const TooltipContent = styled.div`
	font-size: 16px;
`
export const ResultContent = styled.div`
	font-size: 18px;
`
export const ResultsSpacer = styled.div`
	flex: 0 0 60px;
	${laptop} {
		display: none;
	}
`
export const ResultsLowerSpacer = styled.div`
	${laptop} {
		flex: 0 0 20px;
	}
	${tablet} {
		flex: 0 0 35px;
		order: 2;
	}
`
export const PotentialGrowthContainer = styled.div`
	font-size: 54px;
	font-weight: 700;
	${mobile} {
		font-size: 41px;
	}
`
export const PotentialGrowthTitle = styled.div`
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin-top: -10px;
`
export const EasterEggDiv = styled.div`
	line-height: 0.8;
	padding-bottom: 20px;
`

export const GrowthSpacer = styled.div`
	height: 10px;
	${laptop} {
		flex: 0 0 30px;
	}
	${tablet} {
		flex: 0 0 10px;
	}
`

export const TotalContainer = styled.div`
	flex: 0 0 350px;
	${laptop} {
		display: flex;
		flex: 0 0 auto;
		width: 100%;
		align-items: center;
	}
	${tablet} {
		order: 1;
		flex-direction: column;
		text-align: center;
	}
`
export const VariableSpacer = styled.div`
	flex: 0 0 30px;
`
