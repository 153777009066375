import * as React from 'react'
import {
	BasicFlexDiv,
	RootContainer,
	ResultsItemContainer,
	SmallWidthSpacer,
	ResultsSpan,
	TooltipHeader,
	SmallSpacer,
	TooltipContent,
	ResultContent,
	VariableSpacer,
	ResultsSpacer,
	ResultsLowerSpacer,
	TotalContainer,
	PotentialGrowthContainer,
	EasterEggDiv,
	PotentialGrowthTitle,
	ResultsContainer,
	ResultsContent,
	GrowthSpacer,
	ButtonContainer,
} from './components'
import {
	getProductivityIncrease,
	getMissedBidsValue,
	formatCurrency,
	getActualHoursSpent,
} from 'views/components/roi-calc/shared'
import { Button } from 'views/components'
import { cyan } from 'shared/colors'
import QuestionMarkTooltip from 'views/components/question-mark-tooltip'
import { showRoiReportModal } from 'components/modal-manager'

// prettier-ignore
interface StateValues {
	estimators: number;
	hoursSpent: number;
	bidsMissed: number;
	bidValue: number;
	hitRate: number;
}

// prettier-ignore
interface ResultsProps {
	values: StateValues;
}

const ResultsSection: React.FC<ResultsProps> = ({ values }) => {
	const { estimators, hoursSpent, bidsMissed, bidValue, hitRate } = values
	const hoursSaved = estimators * 48
	const productivityIncrease = getProductivityIncrease({
		estimators,
		hoursSpent,
		bidValue,
		hitRate,
	})
	const missedBidsWording =
		bidsMissed === 1 ? (
			<span>
				that <b>1</b> bid
			</span>
		) : (
			<span>
				those <b>{bidsMissed}</b> bids
			</span>
		)
	const missedBidsValue = getMissedBidsValue({
		bidsMissed,
		bidValue,
		hitRate,
	})
	const potentialGrowth = productivityIncrease + missedBidsValue
	// This number is the very max you can reach by only dragging sliders.
	const showPotentialGrowthEasterEgg = potentialGrowth >= 1926000000

	return (
		<RootContainer>
			<ResultsContainer>
				<ResultsContent>
					<div>
						<ResultsItemContainer>
							Productivity <SmallWidthSpacer />
							<ResultsSpan>
								<QuestionMarkTooltip
									title={
										<div>
											<TooltipHeader>
												Bid Board Pro saves each estimator about 48 hrs/yr.
											</TooltipHeader>
											<SmallSpacer />
											<TooltipContent>
												48 hrs x <b>{estimators}</b> estimators ÷{' '}
												<b>{getActualHoursSpent(hoursSpent)}</b> hrs to complete
												bid x <b>${bidValue}k</b> avg bid value x{' '}
												<b>{hitRate}%</b> hit rate
											</TooltipContent>
										</div>
									}
									wide
									fillColor="white"
								/>
							</ResultsSpan>
						</ResultsItemContainer>
						<SmallSpacer />
						<ResultContent>
							You could save <b>{hoursSaved.toLocaleString('en-US')}</b> hrs/yr
							in bidding time. More time means more wins, adding an estimated{' '}
							<b>{formatCurrency(productivityIncrease)}</b>.
						</ResultContent>
					</div>
					<VariableSpacer />
					<div>
						<ResultsItemContainer>
							Missed bids <SmallWidthSpacer />
							<ResultsSpan>
								<QuestionMarkTooltip
									title={
										<div>
											<TooltipHeader>
												Never miss a bid with Bid Board Pro.
											</TooltipHeader>
											<SmallSpacer />
											<TooltipContent>
												<b>{bidsMissed}</b> missed bids/yr x <b>${bidValue}k</b>{' '}
												avg bid value x <b>{hitRate}%</b> hit rate
											</TooltipContent>
										</div>
									}
									wide
									fillColor="white"
								/>
							</ResultsSpan>
						</ResultsItemContainer>
						<SmallSpacer />
						<ResultContent>
							If you don’t miss {missedBidsWording}, with your{' '}
							<b>{values.hitRate}%</b> hit rate, you could earn an additional{' '}
							<b>{formatCurrency(missedBidsValue)}</b>.
						</ResultContent>
					</div>
					<ResultsSpacer />
				</ResultsContent>
				<ResultsLowerSpacer />
				<TotalContainer>
					<BasicFlexDiv>
						<PotentialGrowthContainer>
							{showPotentialGrowthEasterEgg ? (
								<EasterEggDiv>The GDP of a small country</EasterEggDiv>
							) : (
								formatCurrency(potentialGrowth)
							)}
						</PotentialGrowthContainer>
						<PotentialGrowthTitle>IN POTENTIAL GROWTH</PotentialGrowthTitle>
					</BasicFlexDiv>
					<GrowthSpacer />
					<ButtonContainer>
						<Button
							width="100%"
							label="Get a copy of your ROI report"
							backgroundColor={cyan}
							onClick={() =>
								showRoiReportModal({
									...values,
									hoursSaved,
									productivityIncrease,
									missedBidsValue,
									potentialGrowth,
								})
							}
						/>
					</ButtonContainer>
				</TotalContainer>
			</ResultsContainer>
		</RootContainer>
	)
}

export default ResultsSection
