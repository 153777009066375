// @flow
import * as React from 'react'
import { A } from 'glamorous'

import { brandBlue } from 'shared/colors'
import { showRequestDemoModal } from 'components/modal-manager'

const FAQLink = ({ children, ...other }) => (
	<A
		color={brandBlue}
		fontWeight="700"
		target="_blank"
		onClick={e => e.stopPropagation()}
		{...other}
	>
		{children}
	</A>
)

// To be used with new request a demo form when the time comes

// const getReferrerUrlString = () => {
// 	if (typeof window !== 'undefined') {
// 		return `referrer_url=${window.location.href}`
// 	}
// 	return ''
// }

export default [
	{
		question: `Are my bids made public to other GCs and subcontractors on the BuildingConnected network?`,
		answer: `Never. BuildingConnected will never make your bids public to anyone. Everything on your account is private for you and your company.`,
	},
	{
		question: `What types of pricing and plans are available?`,
		answer: (
			<div>
				Upgrading to Bid Board Pro will give you access to all the premium
				features available. Pricing is based on your specific office size and
				usage. To get pricing for your office,{' '}
				<FAQLink
					// href={`https://www.buildingconnected.com/request-a-demo?${getReferrerUrlString()}`}
					onClick={e => {
						e.stopPropagation()
						showRequestDemoModal()
					}}
				>
					contact sales here.
				</FAQLink>
			</div>
		),
	},
	{
		question: `How long does it take to get started?`,
		answer: (
			<div>
				We’ll get your team up and running within a week. Your account rep will
				set up a team training to get you started, and they’ll always be
				available for additional training and questions. Check out our help
				center for additional resources:{' '}
				<FAQLink href="https://buildingconnected.zendesk.com/hc/en-us?solvvy=true">
					support
				</FAQLink>
				.
			</div>
		),
	},
]
