import * as React from 'react'
import styled from 'styled-components'
import {
	Wording,
	WordingBreak,
} from 'views/components/roi-calc/input-item/shared'
import QuestionMarkTooltip from 'views/components/question-mark-tooltip'

const Span = styled.span`
	position: relative;
	top: 2px;
`

export const keys: { [key: string]: string } = {
	ESTIMATORS: 'estimators',
	HOURS_SPENT: 'hoursSpent',
	BIDS_MISSED: 'bidsMissed',
	BID_VALUE: 'bidValue',
	HIT_RATE: 'hitRate',
}

export const ranges = {
	[keys.ESTIMATORS]: { min: 0, max: 9999 },
	[keys.HOURS_SPENT]: { min: 0, max: 999 },
	[keys.BIDS_MISSED]: { min: 0, max: 9999 },
	[keys.BID_VALUE]: { min: 0, max: 9999 },
	[keys.HIT_RATE]: { min: 0, max: 100 },
}

export const defaultCalcState = {
	[keys.ESTIMATORS]: 5,
	[keys.HOURS_SPENT]: 6,
	[keys.BIDS_MISSED]: 4,
	[keys.BID_VALUE]: 50,
	[keys.HIT_RATE]: 5,
}

export const inputs = [
	{
		key: keys.ESTIMATORS,
		wording: (
			<Wording>
				Total estimators on <WordingBreak /> your team
			</Wording>
		),
		min: 1,
		max: 100,
		step: 1,
		default: 5,
	},
	{
		key: keys.HOURS_SPENT,
		wording: (
			<Wording>
				Average hours spent <WordingBreak /> to complete a bid
			</Wording>
		),
		min: 0.5,
		max: 24,
		step: 0.5,
		default: 6,
	},
	{
		key: keys.BIDS_MISSED,
		wording: (
			<Wording>
				Total missed bids <WordingBreak /> per year
			</Wording>
		),
		min: 1,
		max: 30,
		step: 1,
		default: 4,
	},
	{
		key: keys.BID_VALUE,
		wording: (
			<Wording>
				Average bid value{' '}
				<Span>
					<QuestionMarkTooltip title="What’s the average dollar amount you submit for a typical bid?" />
				</Span>
			</Wording>
		),
		formatNumberDisplay: num => {
			if (num === 1000) {
				return '$1M'
			}
			return `$${num}K`
		},
		min: 1,
		max: 1000,
		step: 1,
		default: 4,
	},
	{
		key: keys.HIT_RATE,
		wording: (
			<Wording>
				Hit rate on bids <WordingBreak /> submitted{' '}
				<Span>
					<QuestionMarkTooltip
						title={
							<div>
								How many bids did you win out of all the bids you submitted in
								the last year?
								<br />
								<br />
								Not sure? Enter your best guess. Industry average hit rates are
								5-10%.
							</div>
						}
					/>
				</Span>
			</Wording>
		),
		formatNumberDisplay: num => `${num}%`,
		min: 1,
		max: 20,
		step: 1,
		default: 4,
	},
]
