// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { Hero } from 'views/components'
import H1 from 'components/h1'
import HeaderBackgroundImage from './background-image'
import { breakpoints, tablet, mobile } from 'shared/media-queries'

const getWordingWidth = screenWidth => {
	// if (screenWidth >= breakpoints.desktop) {
	// 	return '590px'
	// } else if (screenWidth >= breakpoints.laptop) {
	// 	return '548px'
	if (screenWidth >= breakpoints.laptop) {
		return '360px'
	}
	return '530px'
}

const heading = (
	<H1
		additionalCss={{
			[tablet]: { fontSize: '51px' },
			[mobile]: { fontSize: '32px' },
		}}
	>
		Bring in more money with Bid Board Pro.
	</H1>
)

const subHeading = (
	<Div
		fontSize="24px"
		lineHeight="1.33"
		css={{
			[mobile]: { fontSize: '18px' },
		}}
	>
		Invest in the tools you need to grow your business.
	</Div>
)

const Header = ({ screenWidth }: { screenWidth: number }) => (
	<Hero
		backgroundImage={<HeaderBackgroundImage screenWidth={screenWidth} />}
		heading={heading}
		subHeading={subHeading}
		wordingWidth={getWordingWidth(screenWidth)}
		demoButtonWording="Get a demo and a quote"
		demoButtonFullWidth={screenWidth >= breakpoints.laptop}
	/>
)

export default Header
